#c-p-bn {
  border: 2px solid #048;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
}

#c-p-bn:hover {
  border: #048 2px solid;
  color: white !important;
}

#c-s-bn {
  border: 2px solid #048;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
}

#c-s-bn:hover {
  color: white !important;
  border: #048 2px solid;
}

.custom-desc {
  color: black;
  font-style: normal;
  font-size: 16px;
  line-height: 28px;
  display: inline-block;
}

.custom-desc:hover {
  color: black;
  font-style: normal;
  font-size: 16px;
  line-height: 28px;
  display: inline-block;
}

.custom-link {
  margin-top: 24px;
  display: inline-block !important;
}

.custom-link:hover {
  margin-top: 24px;
  display: inline-block !important;
}

#s-ttl {
  font-family: "Barlow Condensed";
  text-transform: uppercase;
  color: #048;
  font-size: 28px;
  font-weight: 600;
}

.b-tl {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 150% !important;
  color: black !important;
}

.c-bl > .desc {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 150% !important;
}

.c-bl > .desc > .p {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: black !important;
}

.c-bl > .desc > .p > #custom-option-desc {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 28px !important;
  color: #ffffff;
}

#s-all-bn {
  border: 2px solid #048;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
}

#s-all-bn:hover {
  border: #048 2px solid;
  color: white !important;
}

#s-sv-bn {
  border: 2px solid #048;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
}

#s-sv-bn:hover {
  border: #048 2px solid;
  color: white !important;
}

table a {
  font-size: 1em !important;
  text-decoration: underline !important;
}

#s-hdr {
  border-bottom: 1px solid #262626 !important;
}

.c-bl {
  border: 1px solid #262626;
}

#s-c-bn {
  background-color: #048;
  font-size: 16px;
  width: 39px;
  height: 39px;
}

#s-c-bn:hover {
  background-color: #048;
  font-size: 16px;
  width: 39px;
  height: 39px;
}
